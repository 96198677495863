import React from 'react';
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Header from '../../components/header'
import Container from '../../components/container'
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"
import * as Styles from "./index.module.css"


const Journal = ({data}) => {
    console.log(data)
    return (
        <Layout hasFooter={true}>
            <Helmet>
                <title>Journal updates</title>
                <meta name="description" content="Photographs, stories and other updates in design, technology, photography, nature and travel." />
            </Helmet>
            <Header light={false} />
            <main className={Styles.blog}>
                <Container>
                    { data.allContentfulBlogPost.edges.map(post => {
                        return (
                            <Link to={`/journal/${post.node.heading.replace(/\s+/g, '-').toLowerCase()}`} className={Styles.card}>
                                {/* <img :src="'/journal/' + items[0].slug + '/thumbnail-big.jpg'" width="1262" height="762" /> */}
                                <GatsbyImage 
                                image={post.node.headerImages[0].gatsbyImageData}
                                // alt={props.alt} 
                                width="628"
                                height="463" />
                                <div class={Styles.content}>
                                    {/* <div className={Styles.date}>{post.node.date}</div> */}
                                    <h2 className={Styles.heading}>{post.node.heading}</h2>
                                </div>
                            </Link>
                        )
                    })}
                </Container>
            </main>
        </Layout>
    )
}

export const query = graphql`
  query BlogPosts {
    allContentfulBlogPost {
        edges {
            node {
                id
                heading
                date
                headerImages {
                    gatsbyImageData(aspectRatio: 1.5)
                }
            }
        }
    }
  }
`

export default Journal